.root {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 0;
  height: auto;
  padding-top: 16px;
  padding-bottom: 16px;
}
.root > :global(.__wab_flex-container) {
  align-content: unset;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    flex-direction: column;
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: column;
    align-content: unset;
    align-items: stretch;
    justify-content: flex-start;
    width: calc(100% + 0px);
    min-width: 0;
    height: calc(100% + 20px);
    margin-top: calc(0px - 20px);
    margin-left: calc(0px - 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 20px;
    margin-left: 0px;
  }
}
.freeBox__s0PiX {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 30%;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__s0PiX {
    width: 100%;
    min-width: 0;
  }
}
.link {
  display: flex;
  flex-direction: column;
  align-content: unset;
  align-items: flex-start;
  justify-content: flex-start;
}
.freeBox__cZcIx {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: unset;
  align-items: center;
  justify-content: center;
  max-height: 36px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__cZcIx {
    flex-shrink: 0;
    height: 32px;
  }
}
.logoIcon {
  height: 100%;
  min-height: 0;
  margin: 0px 20px 0px 0px;
  object-fit: cover;
}
.logoIcon > picture > img {
  object-fit: cover;
}
.logoIcon > .__wab_img-spacer > img {
  object-fit: cover;
}
.logoText {
  display: block;
  align-self: flex-start;
  color: rgba(255, 255, 255, 0) /* plasmic-token: w1c6NlKSP */;
  font-weight: 700;
  font-size: 2em;
  line-height: 1em;
  text-align: left;
  background: linear-gradient(90deg, #e7c36e 0%, #ba860e 100%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  -webkit-background-clip: text;
  background-clip: text;
}
@media (min-width: 0px) and (max-width: 768px) {
  .logoText {
    font-size: 1.75em;
  }
}
.freeBox__g1ZUq {
  position: relative;
  display: none;
  flex-direction: row;
  width: auto;
}
.freeBox__g1ZUq > :global(.__wab_flex-container) {
  flex-direction: row;
  align-content: unset;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% + 36px);
  margin-left: calc(0px - 36px);
}
.freeBox__g1ZUq > :global(.__wab_flex-container) > *,
.freeBox__g1ZUq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__g1ZUq > :global(.__wab_flex-container) > picture > img,
.freeBox__g1ZUq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 36px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__g1ZUq > :global(.__wab_flex-container) {
    width: calc(100% + 16px);
    height: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    margin-left: calc(0px - 16px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__g1ZUq > :global(.__wab_flex-container) > *,
  .freeBox__g1ZUq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__g1ZUq > :global(.__wab_flex-container) > picture > img,
  .freeBox__g1ZUq
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
    margin-left: 16px;
  }
}
.linkButton__uT4Me:global(.__wab_instance) {
  position: relative;
}
.linkButton__vjMQo:global(.__wab_instance) {
  position: relative;
}
.linkButton__qyr4S:global(.__wab_instance) {
  position: relative;
}
.freeBox__wBuGw {
  position: relative;
  display: none;
  flex-direction: row;
  width: auto;
}
.freeBox__wBuGw > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% + 20px);
  margin-left: calc(0px - 20px);
}
.freeBox__wBuGw > :global(.__wab_flex-container) > *,
.freeBox__wBuGw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wBuGw > :global(.__wab_flex-container) > picture > img,
.freeBox__wBuGw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__wBuGw > :global(.__wab_flex-container) {
    width: calc(100% + 16px);
    height: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    margin-left: calc(0px - 16px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__wBuGw > :global(.__wab_flex-container) > *,
  .freeBox__wBuGw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__wBuGw > :global(.__wab_flex-container) > picture > img,
  .freeBox__wBuGw
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
    margin-left: 16px;
  }
}
.linkButton__jn38C:global(.__wab_instance) {
  position: relative;
}
.linkButton__aV9Lx:global(.__wab_instance) {
  position: relative;
  display: flex;
}
.text {
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
  text-transform: uppercase;
}
