.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.freeBox__ejTns {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.freeBox__ejTns > :global(.__wab_flex-container) {
  flex-direction: row;
  align-content: unset;
  align-items: stretch;
  justify-content: space-between;
  width: calc(100% + 64px);
  min-width: 0;
  margin-left: calc(0px - 64px);
}
.freeBox__ejTns > :global(.__wab_flex-container) > *,
.freeBox__ejTns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ejTns > :global(.__wab_flex-container) > picture > img,
.freeBox__ejTns
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 64px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ejTns {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ejTns > :global(.__wab_flex-container) {
    flex-direction: column;
    align-content: unset;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% + 0px);
    margin-left: calc(0px - 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__ejTns > :global(.__wab_flex-container) > *,
  .freeBox__ejTns > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ejTns > :global(.__wab_flex-container) > picture > img,
  .freeBox__ejTns
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
  }
}
.freeBox__evNh0 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 35%;
}
.freeBox__evNh0 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: calc(100% + 12px);
  margin-top: calc(0px - 12px);
}
.freeBox__evNh0 > :global(.__wab_flex-container) > *,
.freeBox__evNh0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__evNh0 > :global(.__wab_flex-container) > picture > img,
.freeBox__evNh0
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__evNh0 {
    width: 100%;
    min-width: 0;
    padding: 16px 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__evNh0 > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.text {
  position: relative;
  align-self: flex-start;
  color: rgba(255, 255, 255, 0) /* plasmic-token: w1c6NlKSP */;
  font-weight: 700;
  font-size: 2.5em;
  line-height: 1.4em /* plasmic-token: g_5FLk2zS */;
  text-align: left;
  background: linear-gradient(90deg, #e7c36e 0%, #ba860e 100%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  -webkit-background-clip: text;
  background-clip: text;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text {
    font-size: 1.75em;
  }
}
.featureList:global(.__wab_instance) {
  position: relative;
}
.freeBox__hHlir {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 65%;
}
.freeBox__hHlir > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: calc(100% + 24px);
  margin-top: calc(0px - 24px);
}
.freeBox__hHlir > :global(.__wab_flex-container) > *,
.freeBox__hHlir > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__hHlir > :global(.__wab_flex-container) > picture > img,
.freeBox__hHlir
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__hHlir {
    width: 100%;
    min-width: 0;
    padding: 16px 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__hHlir > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.selectedFeature:global(.__wab_instance) {
  position: relative;
}
