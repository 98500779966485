.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.validausExplanation {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 0;
}
.validausExplanation > :global(.__wab_flex-container) {
  flex-direction: row;
  align-content: unset;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .validausExplanation {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .validausExplanation > :global(.__wab_flex-container) {
    flex-direction: column-reverse;
    align-content: unset;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% + 0px);
    height: calc(100% + 64px);
    margin-top: calc(0px - 64px);
    margin-left: calc(0px - 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .validausExplanation > :global(.__wab_flex-container) > *,
  .validausExplanation
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .validausExplanation > :global(.__wab_flex-container) > picture > img,
  .validausExplanation
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 64px;
    margin-left: 0px;
  }
}
.freeBox__pQ9Bi {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 42%;
}
.freeBox__pQ9Bi > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: calc(100% + 12px);
  margin-top: calc(0px - 12px);
}
.freeBox__pQ9Bi > :global(.__wab_flex-container) > *,
.freeBox__pQ9Bi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pQ9Bi > :global(.__wab_flex-container) > picture > img,
.freeBox__pQ9Bi
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__pQ9Bi {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__pQ9Bi > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.img {
  position: relative;
  object-fit: cover;
}
.img > picture > img {
  object-fit: cover;
}
.img > .__wab_img-spacer > img {
  object-fit: cover;
}
.freeBox__wz833 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 45%;
}
.freeBox__wz833 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: calc(100% + 24px);
  margin-top: calc(0px - 24px);
}
.freeBox__wz833 > :global(.__wab_flex-container) > *,
.freeBox__wz833 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__wz833 > :global(.__wab_flex-container) > picture > img,
.freeBox__wz833
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__wz833 {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__wz833 > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.text___0DOi {
  position: relative;
  align-self: flex-start;
  color: rgba(255, 255, 255, 0) /* plasmic-token: w1c6NlKSP */;
  font-weight: 700;
  font-size: 2.5em;
  line-height: 1.4em /* plasmic-token: g_5FLk2zS */;
  text-align: left;
  background: linear-gradient(90deg, #e7c36e 0%, #ba860e 100%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  -webkit-background-clip: text;
  background-clip: text;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text___0DOi {
    font-size: 1.75em;
  }
}
.featureBullet___8UbEq:global(.__wab_instance) {
  position: relative;
}
.featureBullet__htf0M:global(.__wab_instance) {
  position: relative;
}
.text__aIpYe {
  height: auto;
}
.featureBullet___12H0:global(.__wab_instance) {
  position: relative;
}
