.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 12px 0px;
  background: none;
  border-color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
  border-style: solid;
  cursor: pointer;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-content: unset;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  height: calc(100% + 10px);
  margin-top: calc(0px - 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    padding: 6px 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) {
    width: calc(100% + 0px);
    height: calc(100% + 6px);
    margin-top: calc(0px - 6px);
    margin-left: calc(0px - 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 6px;
    margin-left: 0px;
  }
}
.freeBox__lULqo {
  position: relative;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  transition-duration: 0.25s;
  transition-property: all;
}
.freeBox__lULqo > :global(.__wab_flex-container) {
  flex-direction: row;
  align-content: unset;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% + 20px);
  margin-left: calc(0px - 20px);
}
.freeBox__lULqo > :global(.__wab_flex-container) > *,
.freeBox__lULqo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__lULqo > :global(.__wab_flex-container) > picture > img,
.freeBox__lULqo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.freeBox__iKn5Y {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: stretch;
  align-self: flex-start;
  justify-content: flex-start;
  width: 12px;
}
.slotTargetNumber {
  color: rgb(201, 204, 209) /* plasmic-token: TdEs4epWs */;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
}
.slotTargetNumber__selected {
  color: #d5a32e /* plasmic-token: TpokL1tPV */;
}
.root:hover .slotTargetNumber {
  color: #d5a32e /* plasmic-token: TpokL1tPV */;
}
.slotTargetTitle {
  color: rgb(201, 204, 209) /* plasmic-token: TdEs4epWs */;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
}
.slotTargetTitle > *,
.slotTargetTitle > .__wab_slot > *,
.slotTargetTitle > .__wab_slot > .__wab_slot > *,
.slotTargetTitle > .__wab_slot > .__wab_slot > .__wab_slot > *,
.slotTargetTitle > picture > img,
.slotTargetTitle > .__wab_slot > picture > img,
.slotTargetTitle > .__wab_slot > .__wab_slot > picture > img,
.slotTargetTitle > .__wab_slot > .__wab_slot > .__wab_slot > picture > img {
  transition-duration: 0.25s;
  transition-property: all;
}
.slotTargetTitle__selected {
  color: #d5a32e /* plasmic-token: TpokL1tPV */;
}
.root:hover .slotTargetTitle {
  color: #d5a32e /* plasmic-token: TpokL1tPV */;
}
