@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800&display=swap");
.all {
  position: relative;
  display: block;
  grid-row: auto;
  grid-column: auto;
  box-sizing: border-box;
  margin: 0;
  white-space: inherit;
  background: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-width: 0px;
  box-shadow: none;
  text-decoration-line: none;
}
.img {
  composes: all;
  display: inline-block;
}
.li {
  composes: all;
  display: list-item;
}
.span {
  composes: all;
  position: static;
  display: inline;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  line-height: inherit;
  text-transform: inherit;
}
.input {
  composes: all;
  padding: 2px;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  line-height: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  border: 1px solid lightgray;
}
.textarea {
  composes: all;
  padding: 2px;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  line-height: inherit;
  text-transform: inherit;
  border: 1px solid lightgray;
}
.button {
  composes: all;
  align-items: flex-start;
  padding: 2px 6px;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  line-height: inherit;
  text-align: center;
  text-transform: inherit;
  background-image: none;
  border: 1px solid lightgray;
}
.code {
  composes: all;
  font-family: inherit;
  line-height: inherit;
}
.pre {
  composes: all;
  font-family: inherit;
  line-height: inherit;
}
.p {
  composes: all;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  line-height: inherit;
  text-transform: inherit;
}
.h1 {
  composes: all;
  font-weight: inherit;
  font-size: inherit;
}
.h2 {
  composes: all;
  font-weight: inherit;
  font-size: inherit;
}
.h3 {
  composes: all;
  font-weight: inherit;
  font-size: inherit;
}
.h4 {
  composes: all;
  font-weight: inherit;
  font-size: inherit;
}
.h5 {
  composes: all;
  font-weight: inherit;
  font-size: inherit;
}
.h6 {
  composes: all;
  font-weight: inherit;
  font-size: inherit;
}
.address {
  composes: all;
  font-style: inherit;
}
.a {
  composes: all;
  color: inherit;
}
.ol {
  composes: all;
  padding: 0;
  list-style-type: none;
}
.ul {
  composes: all;
  padding: 0;
  list-style-type: none;
}
.select {
  composes: all;
  padding: 2px 6px;
}
.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  align-items: stretch;
  align-self: start;
  width: 100%;
  min-height: 100vh;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.root_reset {
  color: #666666 /* plasmic-token: xwqDS_Jql3y2 */;
  font-weight: 400;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  white-space: pre-wrap;
  text-align: left;
  text-transform: none;
}
