.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  height: 100%;
  min-height: 0;
  padding: 24px;
  background: radial-gradient(
      ellipse 100% 100% at 0% 0%,
      rgb(101, 103, 107) 0%,
      #282828 100%
    )
    0% 0% / 100% 100% repeat;
  border-radius: 16px;
  box-shadow: none;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-content: unset;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  height: calc(100% + 12px);
  min-height: 0;
  margin-top: calc(0px - 12px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 12px;
}
.svg__sMdhM {
  position: relative;
  flex-shrink: 0;
  align-self: auto;
  width: 50px;
  height: 50px;
  object-fit: cover;
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
}
.slotTargetTitle {
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.4em /* plasmic-token: g_5FLk2zS */;
}
.slotTargetBody {
  color: #eaeaea /* plasmic-token: ng2Kn-ZLwjqV */;
  font-size: 0.8em;
  line-height: 2;
  text-align: left;
}
