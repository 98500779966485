.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-content: unset;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  height: calc(100% + 48px);
  margin-top: calc(0px - 48px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 48px;
}
.title {
  position: relative;
  align-self: center;
  color: rgba(255, 255, 255, 0) /* plasmic-token: w1c6NlKSP */;
  font-weight: 700;
  font-size: 2.5em;
  line-height: 1.2;
  text-align: center;
  background: linear-gradient(90deg, #e7c36e 0%, #ba860e 100%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  -webkit-background-clip: text;
  background-clip: text;
}
@media (min-width: 0px) and (max-width: 768px) {
  .title {
    position: relative;
    align-self: flex-start;
    color: rgba(255, 255, 255, 0) /* plasmic-token: w1c6NlKSP */;
    font-weight: 700;
    font-size: 1.75em;
    line-height: 1.2;
    text-align: left;
    background: linear-gradient(90deg, #e7c36e 0%, #ba860e 100%) 0% 0% / 100%
      100% repeat padding-box /* clip: text **/;
    -webkit-background-clip: text;
    background-clip: text;
  }
}
.freeBox {
  position: relative;
  display: flex;
  flex-direction: row;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: calc(100% + 36px);
  margin-left: calc(0px - 36px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 36px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox {
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox > :global(.__wab_flex-container) {
    flex-direction: column;
    width: calc(100% + 0px);
    height: calc(100% + 24px);
    margin-top: calc(0px - 24px);
    margin-left: calc(0px - 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox > :global(.__wab_flex-container) > *,
  .freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox > :global(.__wab_flex-container) > picture > img,
  .freeBox
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 24px;
    margin-left: 0px;
  }
}
.svg__cTzAq {
  position: relative;
  align-self: auto;
  width: 50px;
  height: 50px;
  object-fit: cover;
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
}
.useCaseCard__aTfgk:global(.__wab_instance) {
  position: relative;
}
.svg__dWr2 {
  position: relative;
  align-self: auto;
  width: 50px;
  height: 50px;
  object-fit: cover;
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
}
.useCaseCard__geM5:global(.__wab_instance) {
  position: relative;
}
.svg__zJ0Jb {
  position: relative;
  align-self: auto;
  width: 50px;
  height: 50px;
  object-fit: cover;
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
}
