.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  height: 100%;
  min-height: 0;
}
.freeBox__fi0C7 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
}
.freeBox__fi0C7 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  height: calc(100% + 64px);
  margin-top: calc(0px - 64px);
}
.freeBox__fi0C7 > :global(.__wab_flex-container) > *,
.freeBox__fi0C7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fi0C7 > :global(.__wab_flex-container) > picture > img,
.freeBox__fi0C7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
.freeBox__mz7H4 {
  position: relative;
  display: flex;
  flex-direction: column;
}
.freeBox__mz7H4 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: calc(100% + 24px);
  margin-top: calc(0px - 24px);
}
.freeBox__mz7H4 > :global(.__wab_flex-container) > *,
.freeBox__mz7H4 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__mz7H4 > :global(.__wab_flex-container) > picture > img,
.freeBox__mz7H4
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.heroRow1 {
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
  font-weight: 700;
  font-size: 3.5em;
  line-height: 1;
  text-align: left;
}
@media (min-width: 0px) and (max-width: 768px) {
  .heroRow1 {
    font-size: 1.75em;
  }
}
.freeBox__degPe {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.heroRow2 {
  display: block;
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
  font-weight: 700;
  font-size: 3.5em;
  line-height: 1;
  text-align: left;
  background: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .heroRow2 {
    font-size: 1.75em;
  }
}
.heroRow22 {
  display: block;
  color: #d5a32e /* plasmic-token: TpokL1tPV */;
  font-weight: 700;
  font-size: 3.5em;
  line-height: 1;
  text-align: left;
  background: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .heroRow22 {
    font-size: 1.75em;
  }
}
.text {
  position: relative;
  align-self: flex-start;
  max-width: 768px;
  color: #eaeaea /* plasmic-token: ng2Kn-ZLwjqV */;
  font-size: 1.2em;
  text-align: left;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text {
    width: 80%;
    font-size: 18px;
  }
}
.linkButton:global(.__wab_instance) {
  position: relative;
  align-self: flex-start;
}
