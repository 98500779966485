.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  height: auto;
  padding-bottom: 32px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  min-width: 0;
  height: calc(100% + 32px);
  margin-top: calc(0px - 32px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 32px;
}
.freeBox {
  position: relative;
  top: auto;
  left: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 0;
  height: auto;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-content: unset;
  align-items: center;
  justify-content: center;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox > :global(.__wab_flex-container) {
    flex-direction: column;
    width: calc(100% + 0px);
    height: calc(100% + 8px);
    margin-top: calc(0px - 8px);
    margin-left: calc(0px - 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox > :global(.__wab_flex-container) > *,
  .freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox > :global(.__wab_flex-container) > picture > img,
  .freeBox
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 8px;
    margin-left: 0px;
  }
}
.slotTargetText {
  color: #eaeaea /* plasmic-token: ng2Kn-ZLwjqV */;
  font-size: 14px;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetText {
    font-size: 12px;
  }
}
