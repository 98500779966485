.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  height: 100%;
  min-height: 0;
  background: #171717;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  height: calc(100% + 130px);
  min-height: 0;
  margin-top: calc(0px - 130px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 130px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) {
    width: calc(100% + 0px);
    height: calc(100% + 100px);
    margin-top: calc(0px - 100px);
    margin-left: calc(0px - 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 100px;
    margin-left: 0px;
  }
}
.freeBox__b7BGi {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: unset;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding-right: 16px;
  padding-left: 16px;
  background: radial-gradient(
      ellipse 60% 80% at 100% 0%,
      rgb(101, 103, 107) 0%,
      #171717 100%
    )
    0% 0% / 100% 100% repeat;
  flex-column-gap: 0px;
}
.freeBox___2RRb0 {
  display: flex;
  flex-direction: column;
  align-content: unset;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  max-width: 1104px;
  height: auto;
  padding: 0px 16px;
}
.navbar2:global(.__wab_instance) {
  position: relative;
  margin: 0px 0px 120px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .navbar2:global(.__wab_instance) {
    margin: 0px 0px 80px;
  }
}
.hero2:global(.__wab_instance) {
  position: relative;
}
.freeBox__or3Sl {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin: 64px 0px 0px;
  opacity: 0.5;
}
.freeBox__or3Sl > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: calc(100% + 24px);
  margin-top: calc(0px - 24px);
}
.freeBox__or3Sl > :global(.__wab_flex-container) > *,
.freeBox__or3Sl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__or3Sl > :global(.__wab_flex-container) > picture > img,
.freeBox__or3Sl
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.freeBox__mqx9Z {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: unset;
  align-items: flex-start;
  align-self: center;
  justify-content: center;
  width: 100%;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__mqx9Z {
    position: relative;
    align-self: flex-start;
  }
}
.text {
  height: auto;
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
  font-size: 0.8em;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text {
    width: 80%;
    max-width: 768px;
    color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
    font-size: 14px;
    text-align: center;
  }
}
.freeBox___0WhBc {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 36px;
}
.freeBox___0WhBc > :global(.__wab_flex-container) {
  flex-direction: row;
  align-content: unset;
  align-items: center;
  justify-content: center;
  width: calc(100% + 40px);
  margin-left: calc(0px - 40px);
}
.freeBox___0WhBc > :global(.__wab_flex-container) > *,
.freeBox___0WhBc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0WhBc > :global(.__wab_flex-container) > picture > img,
.freeBox___0WhBc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
.img__iaoqz {
  position: relative;
  height: 100%;
  min-height: 0;
  object-fit: cover;
}
.img__iaoqz > picture > img {
  object-fit: cover;
}
.img__iaoqz > .__wab_img-spacer > img {
  object-fit: cover;
}
.img__knIcg {
  position: relative;
  height: 100%;
  min-height: 0;
  object-fit: cover;
}
.img__knIcg > picture > img {
  object-fit: cover;
}
.img__knIcg > .__wab_img-spacer > img {
  object-fit: cover;
}
.divider:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.freeBox__nrkJ2 {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: unset;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding-right: 16px;
  padding-left: 16px;
  flex-column-gap: 0px;
}
.freeBox___4Dbeb {
  display: flex;
  flex-direction: column;
  align-content: unset;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  max-width: 1104px;
  height: auto;
  padding: 0px 16px;
}
.sourceOfTruth2:global(.__wab_instance) {
  position: relative;
}
.freeBox__zpAvs {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: unset;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding-right: 16px;
  padding-left: 16px;
  flex-column-gap: 0px;
}
.freeBox__ubGDa {
  display: flex;
  flex-direction: column;
  align-content: unset;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  max-width: 1104px;
  height: auto;
  padding: 0px 16px;
}
.future:global(.__wab_instance) {
  position: relative;
}
.freeBox__dhdoH {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: unset;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding-right: 16px;
  padding-left: 16px;
  flex-column-gap: 0px;
}
.freeBox___9M7F2 {
  display: flex;
  flex-direction: column;
  align-content: unset;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  max-width: 1104px;
  height: auto;
  padding: 0px 16px;
}
.useCases:global(.__wab_instance) {
  position: relative;
}
.freeBox___7SRr5 {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
  padding-right: 16px;
  padding-left: 16px;
  flex-column-gap: 0px;
}
.freeBox___3X7Xi {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  max-width: 1104px;
  height: auto;
}
.freeBox___3X7Xi > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  height: calc(100% + 64px);
  margin-top: calc(0px - 64px);
}
.freeBox___3X7Xi > :global(.__wab_flex-container) > *,
.freeBox___3X7Xi > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___3X7Xi > :global(.__wab_flex-container) > picture > img,
.freeBox___3X7Xi
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 64px;
}
.footer2:global(.__wab_instance) {
  position: relative;
}
