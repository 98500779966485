.root {
  position: relative;
  display: inline-flex;
  width: auto;
  height: auto;
  padding: 8px 24px;
  background: none;
  border-radius: 5px;
}
.root__type_solidBlue {
  background: rgb(24, 119, 242);
}
.root__type_solidBlack {
  background: rgb(24, 25, 26);
}
.root__type_outlineBlue {
  border: 1px solid rgb(24, 119, 242) /* plasmic-token: p-fCTrfjtL4 */;
}
.root__type_small {
  padding: 0px;
}
.root__type_primaryGold {
  background: #d5a32e;
}
.root__narrower {
  padding-right: 20px;
  padding-left: 20px;
}
.root__type_solidBlue:hover {
  background: rgb(23, 99, 207);
}
.root__type_solidBlack:hover {
  background: rgb(50, 52, 54);
}
.slotTargetText {
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
  font-size: 16px;
}
.slotTargetText__type_solidBlue {
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
}
.slotTargetText__type_solidBlack {
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
}
.slotTargetText__type_outlineBlue {
  color: rgb(24, 119, 242) /* plasmic-token: p-fCTrfjtL4 */;
}
.slotTargetText__type_primaryGold {
  color: rgb(0, 0, 0) /* plasmic-token: Hr1egt3PkNIr */;
}
