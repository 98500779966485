.root {
  position: relative;
  display: flex;
  flex-direction: column;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: calc(100% + 10px);
  margin-top: calc(0px - 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10px;
}
.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 650px;
  max-height: 330px;
  padding: 5px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 5px;
}
.img__gnS8I {
  object-fit: cover;
}
.img__gnS8I > picture > img {
  object-fit: cover;
}
.img__gnS8I > .__wab_img-spacer > img {
  object-fit: cover;
}
.slotTargetDescription {
  color: #eaeaea /* plasmic-token: ng2Kn-ZLwjqV */;
  font-size: 0.9em;
  line-height: 2.5em;
  text-align: left;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetDescription {
    color: #eaeaea /* plasmic-token: ng2Kn-ZLwjqV */;
    font-size: 14px;
    text-align: left;
  }
}
