.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: 12px 0px;
  background: none;
  border-color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
  border-style: solid;
  border-radius: 5px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  height: calc(100% + 10px);
  margin-top: calc(0px - 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10px;
}
.freeBox {
  position: relative;
  display: flex;
  flex-direction: row;
  align-self: stretch;
}
.freeBox > :global(.__wab_flex-container) {
  flex-direction: row;
  align-content: unset;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% + 10px);
  margin-left: calc(0px - 10px);
}
.freeBox > :global(.__wab_flex-container) > *,
.freeBox > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox > :global(.__wab_flex-container) > picture > img,
.freeBox
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.svg {
  position: relative;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  object-fit: cover;
  color: #d5a32e /* plasmic-token: TpokL1tPV */;
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg {
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }
}
.slotTargetTitle {
  color: #ffffff /* plasmic-token: nizjtOvz4TpZ */;
  font-weight: 400;
  font-size: 20px;
  text-align: left;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetTitle {
    font-size: 18px;
  }
}
